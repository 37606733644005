import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

function RedirectHandler() {
  const location = useLocation();
  
  if (location.pathname.toLowerCase().includes('bites')) {
    return <Navigate to="/bites-recipes" replace />;
  }

  if (location.pathname.toLowerCase().includes('blog')) {
    return <Navigate to="/blog" replace />;
  }

  if (location.pathname.toLowerCase().includes('bits')) {
    return <Navigate to="/bits" replace />;
  }
  
  return <Navigate to="/about" replace />;
}

export default RedirectHandler;