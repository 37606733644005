import React from 'react';
import { Helmet } from 'react-helmet';

const SEOHelmet = ({ title, description }) => (
  <Helmet>
    <title>{title} | Adrian Henkel</title>
    <meta name="description" content={description} />
  </Helmet>
);

export default SEOHelmet;