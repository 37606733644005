import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: ${props => props.transparent ? 'rgba(255, 255, 255, 0.8)' : 'white'};
  transition: background-color 0.3s ease;
  z-index: 1000;
  border-bottom: 1px solid #444;
`;

const Logo = styled(Link)`
  font-size: 1.2rem;
  font-weight: bold;
  white-space: nowrap;
  line-height: 1.2;
  padding: 0.3rem 0;
  text-decoration: none;
  color: inherit;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    padding: 1rem;
    border-top: 1px solid #444;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #333;
  &:hover {
    text-decoration: underline;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
  position: absolute;
  right: 10px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: none;
  z-index: 1;
  border: 1px solid black;
  border-radius: 10px;

  @media (max-width: 768px) {
    position: static;
    display: block;
    margin-top: 1rem;
    width: fit-content;
    background-color: transparent;
    padding-left: 1rem;
  }
`;

const DropdownLink = styled(Link)`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  &:hover {
    color: black;
    font-size: 18px;
  }
`;

const PopupContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const EastereggTrigger = styled.span`
  cursor: pointer;
`;

const EastereggPopup = styled.div`
  display: ${props => props.show ? 'inline-block' : 'none'};
  margin-left: 10px;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    position: static;
    transform: none;
    width: 60%;
    max-width: 60%;
  }
`;

const PopupContent = styled.p`
  margin: 0;
  white-space: nowrap;
  @media (max-width: 768px) {
    white-space: normal;
    word-wrap: break-word;
  }
`;

const MenuIcon = styled.div`
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  transition: transform 0.3s ease;

  @media (max-width: 768px) {
    display: block;
    transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0)'};
  }
`;

const Navbar = () => {
  const [transparent, setTransparent] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEastereggPopup, setShowEastereggPopup] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [phraseIndex, setPhraseIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const isTransparent = window.scrollY > 0;
      setTransparent(isTransparent);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleBitesClick = (e) => {
    e.preventDefault();
    navigate('/bites-recipes');
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  const phrases = [
    "🤨 Why would you click that?",
    "🥱 Again, okay if you want to...",
    "🙄 Now I am curious! Really why are you clicking that?",
    "🤔 You sure about this? I mean, there's better stuff to do!",
    "📚 You can read a good book or something...",
    "🎥 Or watch a movie?",
    "😶‍🌫️ But if this is what you want, I guess I can't stop you..."
  ];

  const openEasteregg = () => {
    if (showEastereggPopup) {
      setPhraseIndex(0);
      setShowEastereggPopup(false);
    } else {
      setShowEastereggPopup(true);
      setPhraseIndex(0);
    }
  };

  const handleEastereggClick = () => {
    if (phraseIndex < phrases.length - 1) {
      setShowEastereggPopup(true);
      setPhraseIndex(prevIndex => prevIndex + 1);
    } else {
      setPhraseIndex(0);
      setShowEastereggPopup(false);
    }
  };

  return (
    <NavbarContainer transparent={transparent}>
      <Logo to="/" onClick={handleLinkClick}>adrianhenkel</Logo>
      <MenuIcon isOpen={isMenuOpen} onClick={toggleMenu}>
        &lt;
      </MenuIcon>
      <NavLinks isOpen={isMenuOpen}>
        <NavLink to="/about" onClick={handleLinkClick}>About</NavLink>
        <NavLink to="/bits" onClick={handleLinkClick}>Bits</NavLink>
        <PopupContainer>
          <EastereggTrigger onClick={openEasteregg}>&</EastereggTrigger>
          <EastereggPopup show={showEastereggPopup} onClick={handleEastereggClick}>
            <PopupContent>{phrases[phraseIndex]}</PopupContent>
          </EastereggPopup>
        </PopupContainer>
        <DropdownContainer
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
        >
          <NavLink as="span" style={{ cursor: 'pointer' }} onClick={handleBitesClick}>Bites</NavLink>
          <DropdownContent show={showDropdown || isMenuOpen}>
            <DropdownLink to="/bites-picks" onClick={handleLinkClick}>Picks</DropdownLink>
            <DropdownLink to="/bites-recipes" onClick={handleLinkClick}>Recipes</DropdownLink>
          </DropdownContent>
        </DropdownContainer>
        <NavLink to="/blog" onClick={handleLinkClick}>Blog</NavLink>
      </NavLinks>
    </NavbarContainer>
  );
};

export default Navbar;