import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
  margin-top: 4rem;
  padding: 2rem 16.5vw;
  border-top: 1px solid #444;
  text-align: center;

  @media (max-width: 768px) {
    padding: 2rem 5vw;
  }
`;

const ImpressumLink = styled(Link)`
  color: #333;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <ImpressumLink to="/impressum">/Imprint</ImpressumLink>
    </FooterContainer>
  );
}

export default Footer;