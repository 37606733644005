import React from 'react';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Spinner = styled.img`
  width: 100px;
  height: 100px;
`;

const LoadingSpinner = () => (
  <SpinnerContainer>
    <Spinner src="/dna-loading.gif" alt="Loading..." />
  </SpinnerContainer>
);

export default LoadingSpinner;